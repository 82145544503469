@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-gray-800 font-sans;
        background-color: #F8F8FC;
    }

    h1,
    .h1 {
        @apply font-bold font-serif;
        font-size: 40px;
        line-height: 130%;
    }

    h2,
    .h2 {
        @apply font-bold font-serif;
        font-size: 32px;
        line-height: 130%;
    }

    h3,
    .h3 {
        @apply font-bold;
        font-size: 24px;
        line-height: 130%;
    }

    h4,
    .h4 {
        @apply font-semibold;
        font-size: 18px;
        line-height: 130%;
    }

    h5,
    .h5 {
        @apply font-semibold;
        font-size: 16px;
        line-height: 145%;
    }

    a:hover {
        @apply cursor-pointer;
    }
    a:not(.btn):hover {
        @apply underline;
    }

    .facet-gradient { 
        background-size: cover auto;
        background-position-x: left;
        background-position-y: bottom;
        background-image: linear-gradient(to top, var(--tw-gradient-stops));
    }

    .facet {
        background-image: url('assets/facet.svg');
    }
}

@layer components {
    .text-subheader {
        @apply font-semibold;
        font-size: 16px;
        line-height: 145%;
    }
    .text-body-small {
        @apply font-normal;
        font-size: 14px;
        line-height: 150%;
    }
    .text-button {
        @apply font-semibold;
        font-size: 16px;
        line-height: 130%;
    }

    .btn {
        @apply rounded px-4 py-2 text-button;
    }
    .btn:disabled {
        @apply text-gray-400;
    }
    .btn:disabled:hover {
        @apply cursor-not-allowed;
    }
    .btn:not(:disabled):hover {
        @apply cursor-pointer;
    }
    .btn.btn-filled:disabled {
        @apply bg-gray-100;
    }
    .btn.btn-filled:not(:disabled):hover {
        @apply drop-shadow-md;
    }
    .btn.btn-outlined {
        @apply border;
    }
    .btn.btn-outlined:disabled {
        @apply !border-gray-100 !bg-gray-100 !text-gray-400;
    }

    /* filled  */
    .btn.btn-filled.btn-purple {
        @apply bg-purple text-white;
    }
    .btn.btn-filled.btn-purple:hover {
        @apply bg-purple-faint;
    }
    .btn.btn-filled.btn-green-light {
        @apply bg-green-light text-white;
    }
    .btn.btn-filled.btn-green-light:hover {
        @apply bg-green-super-light text-white;
    }
    .btn.btn-filled.btn-green-light:disabled {
        @apply bg-green-light/50;
    }
    .btn.btn-filled.btn-red {
        @apply bg-red text-white;
    }
    .btn.btn-filled.btn-red:hover {
        @apply bg-red/50;
    }

    /* outlined */
    .btn.btn-outlined.btn-purple {
        @apply border-purple text-purple focus-visible:outline-purple;
    }
    .btn.btn-outlined.btn-purple:hover {
        @apply bg-purple/10;
    }
    .btn.btn-outlined.btn-green-light {
        @apply border-green-light text-green-light focus-visible:outline-green-light;
    }
    .btn.btn-outlined.btn-green-light:hover {
        @apply bg-green-light/10;
    }
    .btn.btn-outlined.btn-white {
        @apply border-white text-white;
    }
    .btn.btn-outlined.btn-white:hover {
        @apply bg-white/10;
    }
    .btn.btn-outlined.btn-red {
        @apply border-red text-red focus-visible:outline-red;
    }
    .btn.btn-outlined.btn-red:hover {
        @apply bg-red/10;
    }

    /* text */
    .btn.btn-text:hover {
        @apply bg-gray-800/10;
    }
    .btn.btn-text.btn-purple {
        @apply text-purple;
    }
    .btn.btn-text.btn-purple:hover {
        @apply bg-purple/10;
    }
    .btn.btn-text.btn-green-light {
        @apply text-green-light;
    }
    .btn.btn-text.btn-green-light:hover {
        @apply bg-green-light/10;
    }
    .btn.btn-text.btn-white {
        @apply text-white;
    }
    .btn.btn-text.btn-white:hover {
        @apply bg-white/10;
    }
    .btn.btn-text.btn-red {
        @apply text-red;
    }
    .btn.btn-text.btn-red:hover {
        @apply bg-red/10;
    }
}